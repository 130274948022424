<template>
  <div class="client">
    <div class="container">
      <app-card>
        <h1 class="title client__title">Картка пацієнта</h1>
        <patient-info />
        <h3 class="client__measurement">Додавання операції</h3>
        <operations-form />
      </app-card>
    </div>
  </div>
</template>
<script>
import OperationsForm from "./OperationsForm.vue";
import PatientInfo from "../CardClient/PatientInfo.vue";
// import MeasurementForm from "./MeasurementForm.vue";
export default {
  components: { OperationsForm, PatientInfo },
};
</script>

<style scoped lang="scss">
.client {
  margin-top: 17px;
  margin-bottom: 80px;
  &__title {
    margin-bottom: 26px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 22px;
    }
    @media (max-width: 374px) {
      font-size: 22px;
      margin-bottom: 18px;
    }
  }
  &__subtitle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__measurement {
    display: block;
    font-size: 24px;
    text-align: center;
    margin-bottom: 18px;
    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 15px;
    }
    @media (max-width: 374px) {
      font-size: 18px;
      margin-bottom: 12px;
    }
  }
}
</style>