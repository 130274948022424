<template>
  <div class="operations__wrap">
    <form @submit.prevent="createNewOperations" class="operations__block">
      <div class="operations__item" v-for="item in formInit" :key="item.id">
        <label :for="item.id" class="operations__block-label">{{
          item.label
        }}</label>
        <v-select
          v-if="item.elType === 'select'"
          :placeholder="item.placeholder"
          v-model="item.model"
          :options="item.options"
        />
        <date-picker
          :placeholder="item.placeholder"
          v-else-if="item.elType === 'date'"
          v-model="item.model"
          valueType="format"
          style="width: 100%"
        />
        <base-textarea v-else-if="item.elType === 'textarea'" :item="item" />
      </div>
      <error :error="error" />
      <base-btn>Зберегти</base-btn>
    </form>
    <div class="operations__block" v-if="visible">
      <div class="operations__item">
        <label class="operations__item-label">Імпланти</label>
        <div>
          <div
            v-for="(value, i) in implantValues"
            :key="i"
            class="operations__subblock"
          >
            <div
              @click="deleteImplant(i)"
              v-if="implantValues.length > 1"
              class="operations__delete"
            >
              &#10060;
            </div>
            <div
              class="operations__subitem"
              v-for="item in value"
              :key="item.id"
            >
              <label :for="item.id" class="operations__block-label">{{
                item.label
              }}</label>
              <base-input v-if="item.elType === 'input'" :item="item" />
              <base-textarea v-else :id="item.id" :item="item" />
            </div>
          </div>
        </div>
      </div>
      <base-btn @click="addImplant">Додати імплант</base-btn>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      error: null,
      implantValues: [],
      implantArr: [
        {
          id: "serial",
          label: "Серійний номер(и)",
          placeholder: "",
          elType: "input",
          model: "",
        },
        {
          id: "vendor_code",
          label: "Артикул",
          placeholder: "",
          elType: "input",
          model: "",
        },
        {
          id: "comment",
          label: "Коментар",
          placeholder: "",
          model: "",
        },
      ],
      formInit: [
        {
          id: "type_procedure",
          label: "Тип операції",
          elType: "select",
          placeholder: "Пошук...",
          model: "",
          options: [],
        },
        {
          id: "date_procedure",
          label: "Дата операції",
          elType: "date",
          placeholder: "Оберіть дату",
          model: "",
        },
        {
          id: "comment",
          label: "Коментар",
          elType: "textarea",
          placeholder: "",
          model: "",
        },
      ],
    };
  },
  watch: {
    formInit: {
      deep: true,
      handler(val) {
        if (val) {
          val.map((el) => {
            if (el.id === "type_procedure") {
              if (el.model.need_implant) {
                this.visible = true;
              } else {
                this.visible = false;
              }
            }
          });
        }
      },
    },
  },
  computed: {
    ...mapState({
      patient: (state) => state.currentPatient,
    }),
  },
  methods: {
    ...mapActions(["getAllProcedure", "searchPatient", "createOperations"]),
    ...mapMutations(["setCurrentPatient"]),
    deleteImplant(i) {
      this.implantValues.splice(i, 1);
    },
    addImplant() {
      this.implantValues.push(JSON.parse(JSON.stringify(this.implantArr)));
    },
    createNewOperations() {
      let patientInfo = { patient_id: this.patient._id };
      this.formInit.map((el) => {
        if (el.id === "type_procedure") {
          if (el.model) {
            patientInfo.type_procedure = el.model.id;
          } else {
            this.error = "Не всі поля заповнені";
            setTimeout(() => {
              this.error = null;
            }, 2000);
          }
        } else if (el.elType === "input" || el.elType === "date") {
          if (el.model.trim()) {
            patientInfo[el.id] = el.model;
          } else {
            this.error = "Не всі поля заповнені";
            setTimeout(() => {
              this.error = null;
            }, 2000);
          }
        } else if (el.id === "comment") {
          if (el.model.trim()) {
            patientInfo[el.id] = el.model;
          } else {
            return;
          }
        }
      });
      if (this.visible) {
        let arr = [];
        this.implantValues.map((implant) => {
          let obj = {};
          implant.map((el) => {
            if (el.model) {
              obj[el.id] = el.model;
            } else {
              if (el.id === "comment") {
                return;
              } else {
                this.error = "Не всі поля заповнені";
                setTimeout(() => {
                  this.error = null;
                }, 2000);
              }
            }
          });
          arr.push(obj);
          patientInfo.implants = arr;
        });
      }
      if (!this.error) {
        this.createOperations(patientInfo)
          .then((resp) => {
            if (resp) {
              this.$router.push({ name: "CardPatient" });
            }
          })
          .catch((e) => {
            if (e.response.data.error_text === "token expired") {
              this.$router.replace({ name: "Login" });
            }
          });
      }
    },
  },
  mounted() {
    this.implantValues.push(JSON.parse(JSON.stringify(this.implantArr)));
    this.getAllProcedure().then((resp) => {
      this.formInit.map((el) => {
        if (el.id === "type_procedure") {
          resp.data.map((val) => {
            el.options.push({
              id: val._id,
              label: val.name_view,
              need_implant: val.need_implant,
            });
          });
        }
      });
    });
    if (!this.patient) {
      this.searchPatient(JSON.parse(localStorage.getItem("patient"))).then(
        (resp) => {
          this.setCurrentPatient(resp.data);
        }
      );
    }
  },
};
</script>

<style scoped lang="scss">
.operations {
  &__wrap {
    max-width: 790px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 820px) {
      padding: 0 15px;
      display: block;
    }
  }
  &__block {
    max-width: 356px;
    width: 100%;
    margin: 0 auto;
    &:first-child {
      @media (max-width: 820px) {
        margin-bottom: 20px;
      }
    }

    &-label {
      display: block;
      margin-bottom: 8px;
      @media (max-width: 820px) {
        margin-bottom: 5px;
      }
    }
  }
  &__item {
    margin-bottom: 22px;
    @media (max-width: 820px) {
      margin-bottom: 8px;
    }
    &-label {
      display: block;
      margin-bottom: 9px;
    }
  }
  &__delete {
    text-align: right;
    cursor: pointer;
  }
  &__subitem {
    margin-bottom: 8px;
  }
  &__subblock {
    position: relative;
    margin-bottom: 15px;
    padding-top: 10px;
    &:before {
      position: absolute;
      content: "";
      background-image: url("../../assets/img/icons/line.png");
      background-repeat: no-repeat;
      width: 1px;
      height: 130px;
      top: calc(50% - 40px);
      left: -10px;
    }
  }
}
</style>